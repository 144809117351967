import React from "react"
import { Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Hooks
import useTranslation from "../hooks/useTranslation"

import ArrowLeftIcon from "../assets/icons/arrow.svg"
const FormSuccessPage = () => {
  const { t } = useTranslation()
  return (
    <Layout pageName="form-success" hasLightBackground>
      <SEO title="Thank you!" />
      <section className="section is-hero">
        <div className="container">
          <h1 className="has-text-centered title is-2 has-margin-bottom-6">
            {t("Thank you for getting in touch!")}
          </h1>
          <div className="columns is-centered">
            <div className="column is-4">
              <div
                className="is-card has-text-centered"
                style={{ padding: 64 }}
              >
                <p className="is-size-5 has-margin-bottom-5">
                  {t(
                    "We appreciate you contacting us. One of our colleagues will get back in touch with you soon!"
                  )}
                </p>
                <a
                  href="https://merchant-stage.twispay.com/auth/signup"
                  className="button is-rounded is-success is-fullwidth has-margin-bottom-5"
                >
                  {t("Get Started")}
                </a>
                <Link
                  to="/"
                  className="has-text-primary is-flex is-centered-centered"
                >
                  <span
                    className="icon is-small has-margin-right-3"
                    style={{ transform: "rotate(180deg)" }}
                  >
                    <ArrowLeftIcon />
                  </span>
                  {t("Back to the site")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default FormSuccessPage
